// // Function to update padding-top of content area based on header height
// function updateContentPadding() {
//     if (document.body.id === 'index') {
//         var headerHeightcarousel = document.getElementById('head').offsetHeight; // Get the height of the header
//         document.getElementById('carousel').style.paddingTop = headerHeightcarousel + 'px'; // Update the padding-top of the content area
//     } else {
//         var headerHeight = document.getElementById('head').offsetHeight; // Get the height of the header
//         document.getElementById('wrapper').style.paddingTop = headerHeight + 'px'; // Update the padding-top of the content area
//     }
// }



function updateContentPadding() {
    var headerHeight = document.getElementById('head').offsetHeight; // Get the height of the header
    var screenWidth = window.innerWidth; // Get the width of the window

    // Define different padding values based on screen width and header height
    var paddingValue;
    if (document.body.id === 'index') {
        paddingValue = headerHeight; // Use header height for carousel page
    } else {
        paddingValue = headerHeight; // Use header height for other pages
    }

    // Adjust padding for screens less than 768px
    if (screenWidth < 768) {
        paddingValue = 10; // Adjust this value for smaller screens
    }

    // Update the padding-top of the content area
    if (document.body.id === 'index') {
        document.getElementById('carousel').style.paddingTop = paddingValue + 'px'; // Update padding-top for carousel page
    } else {
        document.getElementById('wrapper').style.paddingTop = paddingValue + 'px'; // Update padding-top for other pages
    }
}

// Listen for scroll events
window.addEventListener('scroll', function() {
    updateContentPadding();
});

// Update padding on initial load and window resize
window.addEventListener('load', function() {
    updateContentPadding();
});

window.addEventListener('resize', function() {
    updateContentPadding();
});




// Function to handle scroll event and add/remove 'scrolled' class to the header
function handleScroll() {
    var header = document.getElementById('head');
    if (window.pageYOffset > 0) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled');
    }
    updateContentPadding(); // Update padding-top of content area on scroll
}

// Add event listener for window scroll, resize events, and initial load
window.addEventListener('scroll', handleScroll);
window.addEventListener('load', function() {
    updateContentPadding();
});

